const path = require('path');

function makePagePath(doc, { pathPrefix } = { pathPrefix: '' }) {
  const urlBuilderParts =
    doc.data && doc.data.url_parts
      ? doc.data.url_parts.map(({ url_part }) => {
          if (Array.isArray(url_part) && url_part[0]) {
            return url_part[0].text;
          }
          return url_part.text;
        })
      : [];

  const parts = [pathPrefix, ...urlBuilderParts].filter(v => v);

  return path.join('/', ...parts, doc.uid === 'home' ? '' : doc.uid, '/');
}

function createPrismicLinkResolver(uids) {
  return function prismicLinkResolver({ node, key, value }) {
    return function(doc) {
      if (uids && !uids.includes(doc.uid)) {
        return '/unpublished-preview';
      }

      if (doc.type === 'page') {
        return makePagePath(doc);
      }

      if (doc.type === 'blog') {
        return `/insights/${doc.uid}/`;
      }
      if (doc.type === 'caseStudy') {
        return `/case-study/${doc.uid}/`;
      }
    };
  };
}

module.exports = {
  prismicLinkResolver: createPrismicLinkResolver(),
  createPrismicLinkResolver,
  makePagePath,
};
