import React, { useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { usePrismicPreview } from 'gatsby-source-prismic';

import Spinner from '../components/spinner';
import { createPrismicLinkResolver } from '../utils/prismic-link-resolver';

const SpinnerContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 15px;
`;

const StyledSpinner = styled(Spinner)`
  width: 30px;
  height: 30px;
`;

const LoadingMessage = styled.div`
  margin-top: 16px;
  font-size: 16px;
`;

const PreviewPage = ({ location }) => {
  const { allPrismicPage, allPrismicBlog } = useStaticQuery(graphql`
    {
      allPrismicPage {
        nodes {
          uid
        }
      }
      allPrismicBlog {
        nodes {
          uid
        }
      }
    }
  `);

  const prismicLinkResolver = useMemo(() => {
    const getUID = node => node.uid;
    const pageUIDs = allPrismicPage.nodes.map(getUID);
    const blogUIDs = allPrismicBlog.nodes.map(getUID);
    return createPrismicLinkResolver([...pageUIDs, ...blogUIDs]);
  }, [allPrismicPage, allPrismicBlog]);

  const { previewData, path } = usePrismicPreview(location, {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver: prismicLinkResolver,
  });

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData;
      navigate(path);
    }
  }, [previewData, path]);

  return (
    <SpinnerContainer>
      <StyledSpinner />
      <LoadingMessage mt={16}>Loading preview</LoadingMessage>
    </SpinnerContainer>
  );
};

export default PreviewPage;
