import React from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledSpinner = styled.div`
  border: 6px solid #eff5fd;
  border-top: 6px solid #71a2dd;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: ${spin} 2s linear infinite;
`;

function Spinner(props) {
  return <StyledSpinner {...props} />;
}

export default Spinner;
